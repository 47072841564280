var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"295e629ffec3e5c5a62a85f7915e6ee45c14f203"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

/* eslint-disable */
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN ?? null;
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT ?? 'local';
const SENTRY_TRACE_SAMPLE_RATE = parseFloat(process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE) ?? 0.0;
const SENTRY_REPLAYS_SESSION_SAMPLE_RATE =
  parseFloat(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE) ?? 0.0;
const SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE =
  parseFloat(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE) ?? 0.0;
/* eslint-enable */

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,

  replaysSessionSampleRate: SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  replaysOnErrorSampleRate: SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,

  integrations: [
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
    Sentry.httpClientIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
});
